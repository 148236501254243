<!-- BackGround -->
<!--<video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" id="bgVideo">-->
<video loop autoplay id="bgVideo">
  <source src="assets/RAMPAGE-2018-AFTERMOVIE.mp4" type="video/mp4">
</video>

<!-- Content -->
<main class="content row">
  <div class="col-lg-8 m-auto">

    <div class="row">

      <!-- TITLE -->
      <div class="col-12 mx-auto text-center">
        <h1>DUBSTEP SPAIN</h1>
        <p style="font-style: italic">Nuevo equipo, Nuevos objetivos, Nuevo futuro</p>
        <p id="prox" style="font-style: italic">{{proxText}}</p>
        <i [hidden]="playButtonHidden" (click)="unmuteAudio()" style="font-size: 2em; cursor: pointer;" class="bi bi-play-circle"></i>
      </div>

    </div>

  </div>

  <!-- NAV -->
  <div class="navbar fixed-bottom pb-5">
    <div class="col-12 mx-auto text-center">
      <div class="container">
        <a target="_blank" href="https://www.instagram.com/dubstep.es/"><i class="bi bi-instagram"></i> @dubstep.es</a><br />
        <a href="mailto:equipo@dubstep.es"><i class="bi bi-envelope"></i> equipo@dubstep.es</a><br /><br />
        <a target="_blank" href="https://www.youtube.com/watch?v=OjA-wMM7Hao"><i class="bi bi-youtube"></i> Rampage 2018 AM</a><br />
        <a target="_blank" href="https://open.spotify.com/track/6YultDhgOUR5bd8DNxdWXD"><i class="bi bi-music-note"></i> Pull The Trigger - Maduk Remix</a><br />

      </div>
    </div>
  </div>
  <div class="fixed-bottom">
    <p class="text-right px-2 blockquote-footer">©2014-2024 Dubstep.es</p>
  </div>
</main>
