import {Component, OnInit} from '@angular/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'anuncio';
  proxText:string = "Próximamente";
  playButtonHidden= true;

  ngOnInit(): void {
    this.changeLang();

    var video = document.getElementById('bgVideo');
    var promise = video["play"]();

    if (promise !== undefined) {
      promise.then(_ => {
        // Autoplay started!
        this.playButtonHidden = true;

      }).catch(error => {
        // Autoplay not allowed!
        // Mute video and try to play again
        video["muted"] = true;
        video["play"]();
        // Show something in the UI that the video is muted
        this.playButtonHidden = false;

      });
    }



  }
  proxOptions = [
    {
      "language": "Spanish",
      "symbol": "es",
      "text": "Próximamente"
    },
    {
      "language": "English",
      "symbol": "en",
      "text": "Coming soon"
    },
    {
      "language": "Arabic",
      "symbol": "ar",
      "text": "قريبا"
    },
    {
      "language": "Dutch",
      "symbol": "nl",
      "text": "Komt binnenkort"
    },
    {
      "language": "French",
      "symbol": "fr",
      "text": "Bientôt disponible"
    },
    {
      "language": "German",
      "symbol": "de",
      "text": "Demnächst"
    },
    {
      "language": "Italian",
      "symbol": "it",
      "text": "Prossimamente"
    },
    {
      "language": "Japanese",
      "symbol": "ja",
      "text": "近日公開"
    },
    {
      "language": "Russian",
      "symbol": "ru",
      "text": "Скоро будет"
    },
    {
      "language": "Catalan",
      "symbol": "ca",
      "text": "Properament"
    }
  ]
  delay = 1*1000/(170/60);// 170 el BPM de la cancion, 1 cantidad de beats por cambio
  contador = 0;
  contador2 = 0;
  private async changeLang() {
    this.proxText = this.proxOptions[this.contador].text;
    this.contador = this.contador+1 >= this.proxOptions.length ? 0:this.contador+1;
    if(this.contador2 != 66)
      await delay(this.delay);
    await this.changeLang();
  }

  unmuteAudio() {
    document.getElementById("bgVideo")["muted"] = false;
    document.getElementById("bgVideo")["play"]();
    this.playButtonHidden = !document.getElementById("bgVideo")["paused"];
  }

}
function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
